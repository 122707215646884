



















































































































import { Vue, Component, VModel, Prop, Watch } from "vue-property-decorator";
import {
  IEmailRecipient,
  IEmailRecipientView,
} from "@/models/entities/email-recipient.interface";
import { Expertise } from "@/models/entities/expertise.interface";
import { required, email } from "@/helpers/rules";
import { utils } from "@/services/api/utils.service";
import { audit } from "@/services/api/audit.service";
import { CustomerCombo } from "@/models/entities/customer.interface";
import axios from "axios";
import CosmopolTextField from "@/components/CosmopolTextField.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import { emailService } from "@/services/api/email.service";
import SnackbarModule from "@/store/modules/snackbar.module";
import { DeepHelper } from "@/helpers/deep.helpers";
import { users } from "@/services/api/users.service";

const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

interface IEmailInfo {
  val: string;
  label: string;
  prop: string;
}

@Component({ name: "EmailConfigForm" })
export default class EmailConfigForm extends Vue {
  @VModel() show: boolean;

  @Prop({ type: Object, required: true })
  item: IEmailRecipientView;

  @Prop({ type: String, required: true })
  emailCategoryId: string;

  required = required;
  email = email;

  valid = true;
  item$: IEmailRecipientView = {} as IEmailRecipientView;
  clone: IEmailRecipientView = {} as IEmailRecipientView;
  search: string = null;
  searchTimeout: number = null;
  loading = false;
  abortController: AbortController = null;
  customers: Partial<CustomerCombo>[] = [];
  references: string[] = [];
  expertises: Expertise[] = [];

  emailInfo: IEmailInfo[] = [
    { val: "", label: "Destinatari", prop: "emailToList" },
    { val: "", label: "Cc", prop: "emailCcList" },
    { val: "", label: "Bcc", prop: "emailBccList" },
  ];

  get equals(): boolean {
    return DeepHelper.equals(this.item$, this.clone);
  }

  get customerIds(): number[] {
    if (!this.item$.listCustomerId) return [];
    return this.item$.listCustomerId.split(",").map((id) => +id);
  }

  set customerIds(ids: number[]) {
    this.item$.listCustomerId = ids.join(",");
  }

  get isAuditCustomer(): boolean {
    return this.emailCategoryId === "AUDIT_CUSTOMER";
  }

  get isAuditByUser(): boolean {
    return this.emailCategoryId === "AUDIT_BYUSER";
  }

  get isEdit(): boolean {
    return !!this.item$?.emailRecipientId;
  }

  get title(): string {
    return `${this.isEdit ? "Modifica" : "Nuova"} configurazione -  ${
      this.emailCategoryId
    }`;
  }

  created(): void {
    this.item$ = Object.assign({}, this.item);
    this.item$.emailCategoryId = this.emailCategoryId;
    this.clone = DeepHelper.clone(this.item$);
    if (this.isAuditCustomer) {
      this.customers = this.item$.customers.map((c) => ({
        customerId: c.customerId,
        customerGesivId: c.customerGesivId,
        customerFullName: c.customerName,
      }));
      return;
    }

    this.isAuditByUser ? this.loadUsers() : this.loadReferences();
    this.loadExpertises();
  }

  @Watch("search")
  searchWatcher(): void {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => this.loadCustomers(), 240);
  }

  async loadCustomers(): Promise<void> {
    if (!this.search) {
      this.customers = [];
      return;
    }
    try {
      this.loading = true;
      if (this.abortController) this.abortController.abort();
      this.abortController = new AbortController();
      const r = await utils.getAllCustomersCombo(
        this.search,
        this.abortController.signal
      );
      this.customers = r.data;
      this.loading = false;
    } catch (e) {
      if (!axios.isCancel(e)) this.loading = false;
    }
  }

  async loadUsers(): Promise<void> {
    overlayModule.showOverlay();
    const r = await users.getAllUsersCombo();
    this.references = r.data;
  }

  async loadReferences(): Promise<void> {
    overlayModule.showOverlay();
    const r = await audit.getAuditEquipmentGroups();
    this.references = r.data;
  }

  async loadExpertises(): Promise<void> {
    overlayModule.showOverlay();
    const r = await utils.getAllExpertise();
    this.expertises = r.data;
  }

  addEmailInfo(info: IEmailInfo, idx: number): void {
    if (!info.val) return;
    if (this.email(info.val) === true) {
      this.item$[info.prop].push(info.val);
      this.emailInfo[idx].val = "";
      (this.$refs[`field${info.prop}`] as CosmopolTextField)[0]?.onClear();
    }
  }

  close(): void {
    this.show = false;
  }

  async save(): Promise<void> {
    const isValid = (
      this.$refs.form as Vue & { validate: () => boolean }
    )?.validate();
    if (!this.valid || !isValid || this.equals) return;
    overlayModule.showOverlay();
    const req: IEmailRecipient = Object.assign({}, this.item$);
    req.emailTo = this.item$.emailToList.join(",") || "";
    req.emailCc = this.item$.emailCcList.join(",") || "";
    req.emailBcc = this.item$.emailBccList.join(",") || "";
    await emailService.insertOrUpdateRecipient(req);
    this.$emit("reload");
    this.close();
    snackbarModule.showSnackbar({
      message: `Configurazione salvata con successo`,
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 5000);
  }
}
