export class DeepHelper {
  static clone<T extends unknown | Array<T>>(data: T): T {
    let result = data;

    if (data && typeof data === "object")
      result = objCloneDeep<typeof data>(data);

    if (Array.isArray(data)) {
      result = data.map((el) => {
        if (Array.isArray(el)) return this.clone(el);
        if (!el || typeof el !== "object") return el;
        return objCloneDeep<typeof el>(el);
      }) as T;
    }

    return result as T;
  }

  static equals(x: unknown, y: unknown, ignoreSort = true): boolean {
    const notArrays = !Array.isArray(x) && !Array.isArray(y);
    const areObjs = typeof x === "object" && typeof y === "object";
    if (ignoreSort && notArrays && areObjs) {
      [x, y] = [x, y].map((el) => sortObjProperty(el));
    }

    return deepEquals(x, y);
  }
}

function objCloneDeep<T>(obj: T): T {
  const result = { ...obj };
  for (const key of Object.keys(obj)) {
    result[key] = DeepHelper.clone(obj[key]);
  }
  return result;
}

function deepEquals(x: unknown, y: unknown): boolean {
  if (typeof x !== "object" && typeof y !== "object") {
    const isX_NaN = isNaN(x as number) && typeof x === "number";
    const isY_NaN = isNaN(y as number) && typeof y === "number";

    if (isX_NaN && isY_NaN) return true;
    return x === y;
  }

  if (typeof x !== typeof y) return false;

  if (x === null && y === null) return true;
  if (x === null || y === null) return false;
  if (x === y) return true;

  if (x instanceof Date && y instanceof Date) {
    return x.toDateString() === y.toDateString();
  }
  if (x instanceof Date || y instanceof Date) return false;

  if (Array.isArray(x) && Array.isArray(y)) {
    if (x.length !== y.length) return false;

    for (let index = 0; index < x.length; index++) {
      if (!DeepHelper.equals(x[index], y[index])) return false;
    }

    return true;
  }

  if (Array.isArray(x) || Array.isArray(y)) return false;
  const xKeys = Object.keys(x);
  const yKeys = Object.keys(y);

  if (xKeys.length !== yKeys.length) return false;
  if (!DeepHelper.equals(xKeys, yKeys)) return false;

  for (let index = 0; index < xKeys.length; index++) {
    const key = xKeys[index];
    const xValue = x[key];
    const yValue = y[key];
    if (!DeepHelper.equals(xValue, yValue)) return false;
  }

  return true;
}

function sortObjProperty(obj: unknown): unknown {
  if (!obj) return obj;
  return Object.keys(obj)
    .sort()
    .reduce((_obj, key) => {
      _obj[key] = obj[key];
      return _obj;
    }, {});
}
