







































import { Vue, Component, Prop } from "vue-property-decorator";
import { emailService } from "@/services/api/email.service";
import { IEmailRecipientView } from "@/models/entities/email-recipient.interface";
import {
  CellType,
  TableHeader,
  BASE_EMAIL_CONFIG_HEADERS,
  EMAIL_CONFIG_CUSTOMERS_HEADERS,
  EMAIL_CONFIG_BY_USER_HEADERS,
  EMAIL_CONFIG_OTHER_HEADERS,
} from "@/models/utils/c-table.interface";
import TablesPaginationModule from "@/store/modules/tables_pagination.module";
import { Pagination } from "@/models/utils/pagination.interface";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";

const overlayModule = getModule(OverlayModule, store);
const tablesPaginationModule = getModule(TablesPaginationModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component({ name: "EmailConfigTable" })
export default class EmailConfigTable extends Vue {
  @Prop({ type: String, required: false, default: null })
  emailCategoryId: string;

  isLoading = false;
  recipients: IEmailRecipientView[] = [];
  headers: TableHeader[] = BASE_EMAIL_CONFIG_HEADERS;
  actions = [
    {
      name: "delete",
      fill: "var(--v-error-base)",
      action: (item: IEmailRecipientView) => this.showConfirmDialog(item),
    },
    {
      name: "edit",
      fill: "var(--v-primary-base)",
      action: (item: IEmailRecipientView) => this.editConfig(item),
    },
  ];
  deleteForm = { show: false, emailRecipientId: null };

  get pagination(): Pagination {
    return tablesPaginationModule.emailConfig;
  }

  set pagination(p: Pagination) {
    tablesPaginationModule.setEmailConfigPagination(p);
  }

  get customerHeaders(): TableHeader[] {
    switch (this.emailCategoryId) {
      case "AUDIT_CUSTOMER":
        return EMAIL_CONFIG_CUSTOMERS_HEADERS;
      case "AUDIT_BYUSER":
        return EMAIL_CONFIG_BY_USER_HEADERS;
      default:
        return EMAIL_CONFIG_OTHER_HEADERS;
    }
  }

  created(): void {
    this.setHeaders();
    this.loadRecipients();
  }

  setHeaders(): void {
    this.headers = [
      ...this.customerHeaders,
      ...BASE_EMAIL_CONFIG_HEADERS,
      { text: "", value: "", cellType: CellType.ACTIONS },
    ];
  }

  async loadRecipients(): Promise<void> {
    if (!this.emailCategoryId) return;

    try {
      this.isLoading = true;
      const r = await emailService.getRecipientsByEmailCategoryId(
        this.emailCategoryId
      );
      this.recipients = r.data;
    } finally {
      this.isLoading = false;
    }
  }

  updatePagination(): void {
    tablesPaginationModule.setEmailConfigPagination(this.pagination);
  }

  showConfirmDialog(item: IEmailRecipientView): void {
    this.deleteForm = { show: true, emailRecipientId: item.emailRecipientId };
  }

  async deleteConfig(): Promise<void> {
    overlayModule.showOverlay();
    await emailService.deleteRecipient(this.deleteForm.emailRecipientId);
    this.loadRecipients();
    snackbarModule.showSnackbar({
      message: `Configurazione eliminata con successo`,
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 5000);
  }

  editConfig(item: IEmailRecipientView): void {
    this.$emit("edit:config", item);
  }
}
