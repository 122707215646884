




















































import { Vue, Component } from "vue-property-decorator";
import EmailConfigTable from "@/views/EmailConfig/components/EmailConfigTable.vue";
import { emailService } from "@/services/api/email.service";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import { IEmailCategory } from "@/models/entities/email-category.interface";
import { CBtn } from "@/models/utils/c-btn.interface";
import EmailConfigForm from "@/views/EmailConfig/components/EmailConfigForm.vue";
import { IEmailRecipientView } from "@/models/entities/email-recipient.interface";

const overlayModule = getModule(OverlayModule, store);

@Component({
  name: "EmailConfig",
  components: { EmailConfigForm, EmailConfigTable },
})
export default class EmailConfig extends Vue {
  reloadKey = 0;
  emailCategoryId: string = null;
  emailCategories: IEmailCategory[] = [];
  newConfigBtn: Omit<CBtn, "type"> = {
    btnClass: "",
    label: "Nuova configurazione",
    labelClass: "c-label-light",
    color: "var(--v-primary-base)",
    icon: {
      name: "plus",
      fill: "var(--v-primary-lighten4)",
      height: 30,
      width: 30,
      iconStyle: "mt-1",
    },
  };
  form = { show: false, item: {} as IEmailRecipientView };

  get emailCategoryDescription(): string {
    return (
      this.emailCategories.find(
        (c) => c.emailCategoryId === this.emailCategoryId
      )?.emailCategoryDescription || ""
    );
  }

  created(): void {
    this.loadCategory();
  }

  async loadCategory(): Promise<void> {
    overlayModule.showOverlay();
    const r = await emailService.getEmailCategories();
    this.emailCategoryId = r.data[0]?.emailCategoryId;
    this.emailCategories = r.data;
  }

  showForm(
    item: IEmailRecipientView = {
      emailToList: [],
      emailCcList: [],
      emailBccList: [],
      expertiseName: "",
      customers: [],
      emailRecipientId: null,
      emailCategoryId: "",
      referenceKey: "",
      emailTo: "",
      emailCc: "",
      emailBcc: "",
      expertiseId: null,
      listCustomerId: "",
    }
  ): void {
    this.form = { show: true, item };
  }
}
