






































































































import { Component, Vue } from "vue-property-decorator";
import axios, { AxiosResponse } from "axios";
import { UserForm as IUserForm } from "@/models/utils/user-form.interface";
import { UserType } from "@/models/entities/user-type.interface";
import { UserById } from "@/models/entities/user.interface";
import { Customer } from "@/models/entities/customer.interface";
import { Expertise } from "@/models/entities/expertise.interface";
import { Role } from "@/models/entities/role.interface";
import { utils } from "@/services/api/utils.service";
import { users } from "@/services/api/users.service";
import { v4 as uuidv4 } from "uuid";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import OverlayModule from "@/store/modules/overlay.module";
import SnackbarModule from "@/store/modules/snackbar.module";

const snackbarModule = getModule(SnackbarModule, store);
const overlayModule = getModule(OverlayModule, store);

const UserExpertiseCard = () =>
  import(
    /* webpackChunkName: "UserExpertiseCard" */ "./components/UserExpertiseCard.vue"
  );
const UserInfoCard = () =>
  import(
    /* webpackChunkName: "UserInfoCard" */ "./components/UserInfoCard.vue"
  );
const UserRoleCard = () =>
  import(
    /* webpackChunkName: "UserRoleCard" */ "./components/UserRoleCard.vue"
  );
const UserTabsCard = () =>
  import(
    /* webpackChunkName: "UserTabsCard" */ "./components/UserTabsCard.vue"
  );

@Component({
  components: {
    "user-expertise-card": UserExpertiseCard,
    "user-info-card": UserInfoCard,
    "user-role-card": UserRoleCard,
    "user-tabs-card": UserTabsCard,
  },
})
export default class UserForm extends Vue {
  private show = false;
  private valid = true;
  private form: Omit<IUserForm, "customerIds"> & { customers: Customer[] } = {
    isEnabled: true,
    userDescription: null,
    email: null,
    userName: null,
    password: null,
    expertiseIds: [],
    userTypeId: null,
    employeeId: null,
    roleIds: [],
    customers: [],
    auditor: null,
  };
  private isNewUser = false;
  private confirmedPwd: string = null;
  private pwdChanged = false;
  private expertiseId = null;
  private formData = {
    formLabel: "Modifica utente",
    userTypes: [] as UserType[],
    roles: {} as { [id: string]: Role[] },
    expertise: [] as Expertise[],
    auditors: [] as string[],
    customers: [] as Customer[],
    search: "",
    customersPage: 1,
    customersNr: 0,
  };
  private disabledTabsCard = null;
  private controller: AbortController = null;

  private async created(): Promise<void> {
    overlayModule.showOverlay();
    const params = this.$route.params;
    if (params.id === "new") {
      this.$set(this.formData, "formLabel", "Nuovo utente");
      this.pwdChanged = true;
      this.isNewUser = true;
    }
    await Promise.all([
      this.loadUserTypes(),
      this.loadExpertise(),
      this.loadAuditors(),
      params.id !== "new" && this.loadUserInfo(params.id),
    ]);
    this.show = true;
  }

  private async loadUserInfo(userId: string): Promise<void> {
    let r: AxiosResponse<UserById>;
    try {
      r = await users.getUserByUserId(Number(userId));
      this.expertiseId = r.data.expertiseId;
      for (const key of Object.keys(this.form)) {
        const val =
          key === "password" ? uuidv4().substring(0, 12) : r.data[key];
        this.$set(this.form, key, val);
      }

      await Promise.all([
        new Set(["Audit", "Customer"]).has(r.data.userTypeId) &&
          this.loadCustomers(),
        r.data.userTypeId && this.loadRolesByUserTypeId(r.data.userTypeId),
      ]);
    } catch (error) {
      error.response.statusText === "Not Found"
        ? await this.$router.push({
            name: "Error",
            params: {
              code: error.response.status,
              message: error.response.data,
            },
          })
        : await this.$router.push({ path: "/error" });
    }
  }

  private async loadUserTypes(): Promise<void> {
    const ut = await utils.getAllUserTypes();
    this.$set(this.formData, "userTypes", ut.data);
  }

  private async loadAuditors(): Promise<void> {
    const r = await utils.getAllAuditors();
    this.$set(this.formData, "auditors", r.data);
  }

  private async loadExpertise(): Promise<void> {
    const e = await utils.getAllExpertise();
    this.$set(this.formData, "expertise", e.data);
  }

  private async loadCustomers(): Promise<void> {
    try {
      if (this.controller) this.controller.abort();
      this.controller = new AbortController();
      const r = await utils.getAllCustomers(
        this.formData.customersPage,
        this.formData.search,
        this.controller.signal
      );

      this.$set(this.formData, "customers", r.data.items);
      this.$set(this.formData, "customersNr", r.data.totalRows);
    } catch (e) {
      if (!axios.isCancel(e)) overlayModule.hideOverlay();
    }
  }

  private async loadRolesByUserTypeId(userTypeId: string): Promise<void> {
    const r = await utils.getRolesByUserTypeId(userTypeId);
    const rolesDict = r.data.reduce((res, value) => {
      (res[value.roleGroup] = res[value.roleGroup] || []).push(value);
      return res;
    }, {});
    this.$set(this.formData, "roles", rolesDict);
  }

  async reloadCustomers(): Promise<void> {
    overlayModule.showOverlay();
    await this.loadCustomers();
  }

  private async onChangeUserType(userTypeId: string): Promise<void> {
    this.$set(this.form, "roleIds", []);
    this.$set(this.form, "customerIds", []);
    this.$set(this.formData, "customers", []);
    this.$set(this.formData, "roles", {});
    this.$set(this.formData, "customersPage", 1);
    this.$set(this.formData, "customersNr", 0);

    if (!userTypeId) return;

    overlayModule.showOverlay();
    await Promise.all([
      new Set(["Audit", "Customer"]).has(userTypeId) && this.loadCustomers(),
      userTypeId && this.loadRolesByUserTypeId(userTypeId),
    ]);
  }

  private onChangeOP(userTypeId: string): void {
    this.$set(this.form, "expertiseIds", []);
    this.$nextTick(
      () =>
        (this.disabledTabsCard =
          userTypeId && userTypeId.toUpperCase() === "OP" ? -1 : null)
    );
  }

  private goToUsersList() {
    this.$router.push({ path: "/users" });
  }

  private async saveForm(): Promise<void> {
    overlayModule.showOverlay();
    let message = "";
    const customerIds = new Set(["Audit", "Customer"]).has(this.form.userTypeId)
      ? this.form.customers.map((c) => c.customerId)
      : [];

    const item: IUserForm = [...Object.keys(this.form), "customerIds"].reduce(
      (res, key) => {
        if (key !== "customers") {
          res[key] = key === "customerIds" ? customerIds : this.form[key];
        }
        return res;
      },
      {} as IUserForm
    );

    if (this.isNewUser) {
      await users.saveUser(item);
      message = `Hai salvato con successo l'utente: ${this.form.userDescription.toUpperCase()}`;
    } else {
      this.pwdChanged === false && this.$set(item, "password", null);
      delete item.userName;
      delete item.userTypeId;
      delete item.employeeId;
      await this.$nextTick();
      await users.updateUser(Number(this.$route.params.id), item);
      message = `Hai modificato con successo l'utente: ${this.form.userDescription.toUpperCase()}`;
    }
    this.goToUsersList();
    snackbarModule.showSnackbar({
      message: message,
      type: "success",
    });
    setTimeout(() => snackbarModule.hideSnackbar(), 5000);
  }
}
