import { AuditAlert } from "@/models/entities/audit-alert.interface";
import { AuditRow } from "@/models/entities/audit-row.interface";
import {
  AuditEquipmentQuestion,
  AuditEquipmentAnswer,
} from "@/models/entities/audit-survey.interface";
import { Audit } from "@/models/entities/audit.interface";
import { ExportReponse } from "@/models/entities/export-response.interface";
import { AuditRequest } from "@/models/requests/audit-request.interface";
import { Paginated } from "@/models/utils/paginated.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "audit";

class AuditService extends ApiService {
  getAllAudits(request: AuditRequest, signal: AbortSignal = null) {
    return this.getAll<Paginated<Audit>>(CONTROLLER, {
      params: request,
      signal: signal,
    });
  }

  getAlertConditions() {
    return this.getAll<AuditAlert[]>(`${CONTROLLER}/alert-conditions`);
  }

  getQuestions(customerAuditCode: string) {
    return this.getAll<AuditEquipmentQuestion[]>(
      `${CONTROLLER}/questions/${customerAuditCode}`
    );
  }

  getAnswers(auditSurveyId: number) {
    return this.getByID<number, AuditEquipmentAnswer>(
      `${CONTROLLER}/answers`,
      auditSurveyId
    );
  }

  getAuditEquipmentGroups() {
    return this.getAll<string[]>(`${CONTROLLER}/equipment-groups`);
  }

  exportAudits(
    request: Omit<AuditRequest, "rows" | "page">,
    signal: AbortSignal = null
  ) {
    return this.getAll<ExportReponse>(`${CONTROLLER}/export`, {
      params: request,
      signal: signal,
    });
  }

  exportAuditPDF(
    customerAuditCode: string,
    auditSurveyId: number,
    signal: AbortSignal = null
  ) {
    return this.getAll<ExportReponse>(
      `${CONTROLLER}/export/audit/${customerAuditCode}`,
      {
        params: { auditSurveyId },
        signal: signal,
      }
    );
  }

  updateIgnoreAnomalies(auditSurveyId: number, ignoreAnomalies: number) {
    return this.update<number, AuditRow>(
      `${CONTROLLER}/${auditSurveyId}/${ignoreAnomalies}`,
      ignoreAnomalies
    );
  }

  getAuditById(auditSurveyId: number) {
    return this.getByID<number, AuditRow>(`${CONTROLLER}`, auditSurveyId);
  }
}

export const audit = new AuditService();
