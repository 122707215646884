import { ApiService } from "../api.service";
import {
  IEmailRecipient,
  IEmailRecipientView,
} from "@/models/entities/email-recipient.interface";
import { IEmailCategory } from "@/models/entities/email-category.interface";

const CONTROLLER = "email";

class EmailService extends ApiService {
  getEmailCategories() {
    return this.getAll<IEmailCategory[]>(`${CONTROLLER}/categories`);
  }

  getRecipientsByEmailCategoryId(emailCategoryId: string) {
    return this.getAll<IEmailRecipientView[]>(
      `${CONTROLLER}/category/${emailCategoryId}/recipients`
    );
  }

  insertOrUpdateRecipient(emailRecipient: IEmailRecipient) {
    return this.post<IEmailRecipient, void>(
      `${CONTROLLER}/recipient`,
      emailRecipient
    );
  }

  deleteRecipient(emailRecipientId: number) {
    return this.delete<number, void>(
      `${CONTROLLER}/recipient`,
      emailRecipientId
    );
  }
}

export const emailService = new EmailService();
